import React from 'react';
import LogoLeibnizDe from './logo_leibniz_de.svg';
import LogoLeibnizEn from './logo_leibniz_en.svg';
import { useSelector } from 'react-redux';
import { Segment, Grid, Container } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import YoutubeSVG from './youtube.svg';
import InstagramSVG from './instagram.svg';
import RSSSVG from './podcast-solid.svg';
import LinkedinSVG from './linkedin.svg';
import MastodonSVG from './mastodon.svg';
import { Image } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';

import arrowUpSVG from 'volto-ipn/icons/arrow-up.svg';
import { scrollToTop } from 'volto-ipn/helpers';
import LogoDe from 'volto-ipn/images/Logo_de.svg';
import LogoEn from 'volto-ipn/images/Logo_en.svg';

import './FooterTop.scss';

function FooterTop() {
  const lang = useSelector((state) => state.intl.locale);
  const navroot = useSelector((state) => state.navroot.data);
  const in_subsite = navroot?.navroot?.['@type'] === 'subsite';
  const LogoLeibniz = lang === 'en' ? LogoLeibnizEn : LogoLeibnizDe;
  const LogoIPN = lang === 'en' ? LogoEn : LogoDe;
  const LogoFooter = in_subsite && navroot?.navroot?.footerlogo?.download;

  return (
    <Segment vertical className="footer-top">
      <Container>
        <hr className={'grey'} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              {!in_subsite ? (
                <Image
                  className="logo-leibniz"
                  src={LogoLeibniz}
                  alt="Logo Leibniz"
                />
              ) : (
                <>
                  <UniversalLink href="/" title="IPN Website">
                    <Image
                      className="logo-ipn"
                      src={LogoIPN}
                      alt="IPN Website"
                      title="IPN Website"
                    />
                  </UniversalLink>
                  <ConditionalLink
                    to={LogoFooter && navroot?.navroot?.footerlogo_link}
                    condition={navroot?.navroot?.footerlogo_link}
                  >
                    <Image className="logo-subsite" src={LogoFooter} />
                  </ConditionalLink>
                </>
              )}
            </Grid.Column>
            <Grid.Column width={6} textAlign="right">
              <ul className="social-icons-list">
                <li>
                  <UniversalLink
                    href="https://www.youtube.com/channel/UC6hz0k5jrbrEDT9Oyj7Snjw?view_as=subscriber"
                    title="Youtube"
                  >
                    <Image
                      alt="Youtube"
                      height="22px"
                      src={YoutubeSVG}
                      verticalAlign="top"
                      className="social-icons youtube-logo"
                      floated="right"
                    />
                  </UniversalLink>
                </li>
                <li>
                  <UniversalLink
                    href="https://www.instagram.com/ipn_kiel/"
                    title="Instagram"
                  >
                    <Image
                      alt="Instagram"
                      height="22px"
                      src={InstagramSVG}
                      verticalAlign="top"
                      className="social-icons instagram-logo"
                      floated="right"
                    />
                  </UniversalLink>
                </li>
                <li>
                  <UniversalLink
                    href="https://www.linkedin.com/company/ipn---leibniz-institute-for-science-and-mathematics-education/?viewAsMember=true"
                    title="LinkedIn"
                  >
                    <Image
                      alt="LinkedIn"
                      height="22px"
                      src={LinkedinSVG}
                      verticalAlign="top"
                      className="social-icons linkedin-logo"
                      floated="right"
                    />
                  </UniversalLink>
                </li>
                <li>
                  <UniversalLink
                    href="https://eduresearch.social/@IPN_Kiel"
                    title="Mastodon"
                  >
                    <Image
                      alt="Mastodon"
                      height="16px"
                      src={MastodonSVG}
                      verticalAlign="top"
                      className="social-icons mastodon-logo"
                      floated="right"
                    />
                  </UniversalLink>
                </li>
                <li>
                  <UniversalLink href="/de/fuer-die-gesellschaft/podcasts-videos">
                    <Image
                      height="22px"
                      alt="Podcasts"
                      src={RSSSVG}
                      verticalAlign="top"
                      className="social-icons rss-logo"
                      floated="right"
                    />
                  </UniversalLink>
                </li>
              </ul>
              <a
                href="#todo"
                onClick={(event) => scrollToTop(event, '')}
                className="go-to-top"
              >
                <Icon size="16px" name={arrowUpSVG} />
                <FormattedMessage id="to-top" defaultMessage="to the top" />
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}

export default FooterTop;
